<template>
  <div
    style="color:var(--color-braun); background-color:var(--color-rosa);"
    class="p-7 lg:p-10 flex items-center justify-center gap-x-5 text-center uppercase tracking-widest"
  >
    Ein guter Kaffee am morgen vertreibt kummer und so manche sorgen!
    <img
      class="w-10 h-10"
      src="@/assets/Grafiken/heart.svg"
    >
  </div>
</template>

<script>
export default {
  name: 'Quotation'
}
</script>

<style scoped lang="scss">

</style>
