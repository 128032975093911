<template>
  <div>
    <MobileHeader class="lg:invisible sticky lg:fixed top-0 left-0 right-0 z-10" />
    <div class="flex">
      <div class="invisible lg:visible lg:w-2/6 xl:w-1/5" />
      <SideBar class="invisible lg:visible h-screen fixed lg:w-2/6 xl:w-1/5" />

      <div class="w-100 lg:w-4/6 xl:w-4/5">
        <Hero />
        <Quotation />
        <Story />
        <Menu />
        <GoodBye />
        <Footer />
      </div>
    </div>
    <MobileFooter class="lg:invisible sticky lg:fixed bottom-0 left-0 right-0 z-10" />
  </div>
</template>

<script>
import MobileHeader from '@/components/MobileHeader.vue'
import MobileFooter from '@/components/MobileFooter.vue'
import SideBar from '@/components/SideBar.vue'
import Hero from '@/components/Hero.vue'
import Quotation from '@/components/Quotation.vue'

const Story = () => import('@/components/Story.vue')
const Menu = () => import('@/components/Menu.vue')
const GoodBye = () => import('@/components/GoodBye.vue')
const Footer = () => import('@/components/Footer.vue')

export default {
  name: 'Home',
  components: {
    SideBar,
    Hero,
    Quotation,
    Story,
    Menu,
    GoodBye,
    Footer,
    MobileHeader,
    MobileFooter
  }
}
</script>
