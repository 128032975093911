<template>
  <div
    style="background-color:var(--color-rosa);"
    class="drop-shadow-md py-3"
  >
    <div
      v-if="showTime"
      class="bg-white m-3 p-3 rounded-lg drop-shadow-md"
    >
      Öffnungszeiten:
      <ul class="mb-4">
        <li><b>Montag:</b> Ruhetag</li>
        <li><b>DI-SO:</b> 10:00 Uhr - 18:00 Uhr</li>
      </ul>
      <button
        type="button"
        class="inline-block rounded border border-black px-12 py-3 text-sm font-medium"
        @click="showTime = false"
      >
        Schließen
      </button>
    </div>
    <div class="p-2 grid grid-cols-3 items-center justify-items-center">
      <a href="tel:+436604111650">
        <img
          class="h-[2rem]"
          src="@/assets/Grafiken/phone.svg"
        >
      </a>
      <span
        :class="{ 'drop-shadow-xl': showTime }"
        @click="showTime = !showTime"
      >
        <img
          class="h-[2rem]"
          src="@/assets/Grafiken/clock.svg"
        >
      </span>
      <a href="mailto:info@genuss-cafe.at">
        <img
          class="h-[2rem]"
          src="@/assets/Grafiken/email.svg"
        >
      </a>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'MobileFooter',
  setup() {
    return {
      showTime: ref(false)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
