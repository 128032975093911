<template>
  <div id="app">
    <RouterView />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="css">
@font-face {
  font-family: 'Boska';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('~@/assets/fonts/Boska-Variable.ttf');
}

@font-face {
  font-family: 'GeneralSans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('~@/assets/fonts/GeneralSans-Regular.ttf');
}

@font-face {
  font-family: 'GeneralSans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('~@/assets/fonts/GeneralSans-Semibold.ttf');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: GeneralSans, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  :root {
    --color-rosa: #EBC3BF;
    --color-braun: #402715;
    --color-beige: #FBF7F2;
    --color-braun-light: #C4A78D;
  }
}
</style>
