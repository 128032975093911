<template>
  <div
    style="background-color:var(--color-braun-light);"
    class="flex text-white"
  >
    <div class="my-10 mx-auto grid w-5/6 2xl:w-4/6">
      <div class="flex flex-col items-center">
        <img
          class="opacity-30 mb-10 w-4/6"
          src="@/assets/Grafiken/Genuss-Cafe-Icon-Braun.svg"
        >
        <img
          src="@/assets/Grafiken/Genuss-Cafe-Logo-weiss.svg"
        >
      </div>
      <div>
        <div>
          Telefonisch erreichbar unter:
        </div>
        <div class="flex items-center gap-x-2 mb-6">
          <img
            class="w-5 h-5"
            src="@/assets/Grafiken/phone.svg"
          >
          <b><a href="tel:+436604111650">+43 660 41 11 650</a></b>
        </div>
        <div>
          E-Mail:
        </div>
        <div class="flex items-center gap-x-2 mb-6">
          <img
            class="w-5 h-5"
            src="@/assets/Grafiken/email.svg"
          >
          <b><a href="mailto:info@genuss-cafe.at">info@genuss-cafe.at</a></b>
        </div>
        <div class="my-10">
          Öffnungszeiten:
          <ul>
            <li><b>Montag:</b> Ruhetag</li>
            <li><b>DI-SO:</b> 10:00 Uhr - 18:00 Uhr</li>
          </ul>
        </div>
        <div
          class="h-px mb-6"
          style="background-color:var(--color-rosa);"
        />
        <div class="">
          Mozartplatz 2a - 5340 St.Gilgen
        </div>
      </div>
      <div class="mt-auto">
        Made with ♥️ by <b><a
          href="https://eyedea.at/"
          target="_blank"
          rel="noopener"
        >EYEDEA</a></b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideBar'
}
</script>

<style scoped lang="scss">

</style>
