<template>
  <div class="bg-gray-200">
    <video
      playsinline
      autoplay
      loop
      muted
      class="w-full md:hidden aspect-[9/16]"
    >
      <source
        src="@/assets/Video/cafe_genuss_webvideo_hoch_NEU_V1.mp4"
        type="video/mp4"
      >
      Your browser does not support the video tag.
    </video>
    <video
      playsinline
      autoplay
      loop
      muted
      class="w-full hidden md:block aspect-[16/9]"
    >
      <source
        src="@/assets/Video/cafe_genuss_webvideo_quer_NEU_V1.mp4"
        type="video/mp4"
      >
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style scoped lang="scss">

</style>
