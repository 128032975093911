<template>
  <div
    style="background-color:var(--color-braun-light);"
    class="drop-shadow-md py-3"
  >
    <div class="grid justify-items-end">
      <img
        class="h-[3rem]"
        src="@/assets/Grafiken/Genuss-Cafe-Logo-weiss.svg"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileHeader'
}
</script>

<style scoped lang="scss">

</style>
